import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const Contact = () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
        }
      }
    }
  `)

  return(
    <Layout>
      <Seo title="Contact" />
      <h1>Contact Me</h1>
      <p>When Saturn is in ascension and the blood-moon rises in the east you may contact me via this page. <a href={data.site.siteMetadata.email}>{data.site.siteMetadata.email}</a></p>
    </Layout>
  )

}

export default Contact
